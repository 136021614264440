import styled from "styled-components"
import { colorPrimary } from "../utilities/variables"

export const HeaderLargeText = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0;
  color: ${colorPrimary};
  @media (min-width: 700px) {
    font-size: 35px;
    line-height: 45px;
  }
`

export const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0;
  color: ${colorPrimary};
  @media (min-width: 700px) {
    font-size: 27px;
    line-height: 39px;
  }
`

export const SubHeaderText = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${colorPrimary};
  max-width: 710px;
  margin: 0 auto;
  @media (min-width: 700px) {
    font-size: 20px;
    line-height: 26px;
  }
  padding: 0 20px;
  @media (min-width: 500px) {
    padding: 0;
  }
`

export const LgContentText = styled.h4`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0;
  color: ${colorPrimary};
  max-width: 740px;
  padding: 0 15px;
`

export const ContentText = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0;
  color: ${colorPrimary};
  /* word-break: break-all; */
  max-width: ${props => (props.sub ? `700px` : `auto`)};
  margin: ${props => (props.sub ? `auto` : ``)};
  padding: ${props => (props.sub ? `0 20px` : ``)};
  padding-bottom: ${props => (props.padding ? `50px` : ``)};
  @media (min-width: 500px) {
    padding: 0;
  }
`

export const SubContentText = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0;
  opacity: ${props => (props.faded ? `0.8` : `1`)};
  margin: ${props => (props.noMargin ? `0` : `0 0 20px 0`)};
  color: ${colorPrimary};
  ${props => (props.bold ? `font-weight: bold` : ``)}
`

export const SmallContentText = styled.small`
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0;
  color: ${colorPrimary};
`
